// Styles
import '../scss/main.scss';

// Scripts
import { offer } from '@shop/frontend/dist/desktop/index';
import '/vendor/js/slick.js';

$(document).ready(function () {
  offer.getState(function (data) {
    const iso_code = offer.getCountryIsoCode();
    if (iso_code === 'IL') {
      $("input[name='firstName'], input[name='lastName'], input[name='address'], input[name='city']")
        .keyup(function (e) {
          const _this = e.currentTarget;
          _this.value = _this.value.replace(/[^a-zA-Z0-9-z/.'"№#\ \,\-]{1}/g, '');
        })
        .change(function (e) {
          const _this = e.currentTarget;
          _this.value = _this.value.replace(/[^a-zA-Z0-9-z/.'"№#\ \,\-]{1}/g, '');
        });
    }
  });
  $('.slider').slick({
    dots: true,
    autoplay: true,
    autoplaySpeed: 15000,
    adaptiveHeight: true,
    fade: false,
    focusOnSelect: false,
    arrows: false,
    //slidesToScroll: 3,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
});
